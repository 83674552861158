html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  background: #ffffff;
}

a {
  color: inherit;
  text-decoration: none;
}

.pageContainer {
  width: 100%;
  max-width: 700px;
  border-radius: 15px;
  background-color: rgb(240, 243, 247);
}

:root {
  --text-primary: #ffffff;
  --text-primary-light: #090f1f;
  --text-description: #979bb0;
  --text-secondary: #979bb0;
  --background-primary: #090e1f;
  --background-content-primary: #1c2131;
  --background-green: #7afb8f;
  --border-primary: #50d866;

  --main-border-width: 1px;

  --main-border-radius: 24px;
}

/* Кастомный scroll */
* {
  scrollbar-width: thin;
}

html {
  scrollbar-color: #292e31 #12171a;
  scrollbar-width: thin;
}

html::-webkit-scrollbar {
  width: 6px;
  background-color: #12171a;
}

html::-webkit-thumb,
html::-webkit-scrollbar-thumb {
  background-color: #292e31;
}

/* Для Webkit (Chrome, Safari) */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  html {
    scrollbar-color: #292e31 #12171a;
    scrollbar-width: thin;
  }
}

/* Для Firefox */
@-moz-document url-prefix() {
  html {
    scrollbar-color: #292e31 #12171a;
    scrollbar-width: thin;
  }
}

/* Для Opera */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  html::-webkit-scrollbar {
    width: 6px;
  }

  html::-webkit-scrollbar-thumb {
    background-color: #292e31;
  }

  html::-webkit-scrollbar-track {
    background-color: #12171a;
  }
}
